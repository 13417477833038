/*
  Authors : initappz (Rahul Jograna)
  Website : https://csuk.uk/
  App Name : Contactless Shopping App  This App Template Source code is licensed as per the
  terms found in the Website https://csuk.uk/license
  Copyright and Good Faith Purchasers © 2022-present Contactless Shopping.
*/
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseUrl: 'https://api.csuk.uk/public/api/', // ex https://csuk.uk/public/api/ don't forgot to add public/api/ at the end
  imageUrl: 'https://api.csuk.uk/public/storage/images/', // ex https://csuk.uk/public/storage/images/ don't forgot to add public/storage/images at the end
  firebase: {
    apiKey: "YOURKEY",
    authDomain: "YOURKEY",
    projectId: "YOURKEY",
    storageBucket: "YOURKEY",
    messagingSenderId: "YOURKEY",
    appId: "YOURKEY"
  }
};
